<template>
    <section id="dashboard-campaigns">
        <b-row class="title mb-5" v-if="$role(['campaigns.create', 'campaigns.update'])">
            <b-col>
                <card-header v-bind="cardHeaderOptions"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <shot-pattern-table/>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import {BRow, BCol} from "bootstrap-vue";
import CardHeader from "@/views/components/CardHeader.vue";
import ShotPatternTable from "@/views/pages/dashboard/shots-patterns/components/ShotPatternTable";

let configTableShots = {
    fields: [
        {
            key: "nome",
            label: "Nome",
            class: "text-left",
        },
        {
            key: "quantidade",
            label: "Qtd.",
        },
        {
            key: "duracao",
            label: "Duração",
        },
        {
            key: "status",
            label: "Status",
        },
        {
            key: "actions",
            label: "",
            class: "text-left d-flex justify-content-between",
        },
    ],
    isHeader: true,
};

export default {
    components: {
        BRow,
        BCol,
        CardHeader,
        ShotPatternTable,
    },
    data() {
        return {
            data: {},
            configTableShots,
            cardHeaderOptions: {
                title: "Gerenciar Disparos",
                description:
                    "Crie ou gerencie seus disparos de forma rápida, nesta página você tem controle total sobre todos os disparos de campanhas.",
                icon: require("@/assets/images/icons/chat-settings.svg"),
                link: "shots/patterns/new",
                textButton: "Criar disparo",
            },
        };
    },
    created() {
    },
    methods: {},
};
</script>
