<template>
    <b-card no-body>
        <b-card-body class="text-center">
            <common-table-custom
                :show-details="false"
                title="Modelos de Disparo"
                v-bind="configTableShotPattern"
                :loading="loading"
            >
                <template v-slot:filters>
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink">
                            <select v-model="configTableShotPattern.filters.order_column"
                                    class="form-control like-dropdown my-1">
                                <option :value="null" hidden selected>Filtrar por</option>
                                <option value="name">Nome</option>
                                <option value="created_at">Data de Criação</option>
                            </select>
                        </div>
                        <div class="flex-shrink">
                            <select v-model="configTableShotPattern.filters.order"
                                    class="form-control like-dropdown mx-md-2 my-1">
                                <option :value="null" hidden selected>Classificar por</option>
                                <option value="asc">Ascendente</option>
                                <option value="desc">Descendente</option>
                            </select>
                        </div>
                        <div class="input-group input-group-merge search my-1 w-100 flex-grow-1" role="group"
                             style="width: 300px !important;">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <feather-icon icon="SearchIcon" size="25"/>
                                </div>
                            </div>
                            <input
                                v-model="configTableShotPattern.filters.search"
                                class="form-control"
                                placeholder="Buscar modelo"
                                type="text"
                            >
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <td class="text-left">Total</td>
                    <td>{{ configTableShotPattern.misc ? configTableShotPattern.misc.total : 0 }}</td>
                    <td
                        :colspan="5"
                        class="text-right"
                    >
                        <b-link
                            v-if="configTableShotPattern.misc && configTableShotPattern.misc.current_page != configTableShotPattern.misc.last_page"
                            @click="getShotPattern(configTableShotPattern.misc.current_page + 1)"
                        >
                            + Mostrar mais
                        </b-link>
                    </td>
                </template>
            </common-table-custom>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardBody,
    BLink,
} from "bootstrap-vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import api from '@/api';
import { formatPhone } from "@/api/functions";
import moment from "moment";

export default {
    components: {
        BCard,
        BCardBody,
        CommonTableCustom,
        BLink
    },
    data() {
        return {
            loading: false,
            configTableShotPattern: {
                fields: [
                    {
                        key: "name",
                        label: "Nome",
                        sortable: false,
                        sortDirection: "desc",
                        class: "text-left",
                    },
                    {
                        key: "begin_date",
                        label: "Inicia",
                        sortable: false,
                        formatter: value => moment(value).format('DD/MM/YYYY')
                    },
                    {
                        key: "finish_date",
                        label: "Termina",
                        sortable: false,
                        formatter: value => moment(value).format('DD/MM/YYYY')
                    },
                    {
                        key: "actions",
                        label: "",
                        class: "text-right d-flex justify-content-around",
                        sortable: false,
                    },
                ],
                items: [],
                actions: [
                    {
                        label: "Duplicar",
                        name: "duplicate",
                        onClick: this.duplicateShotPattern,
                        roles: ['campaigns.update', 'campaigns.create']
                    },
                    {
                        label: "Editar",
                        name: "edit",
                        onClick: this.editShotPattern,
                        roles: ['campaigns.update', 'campaigns.create']
                    },
                    {
                        label: "Deletar",
                        name: "delete",
                        onClick: this.deleteShotPattern,
                        roles: ['campaigns.delete']
                    },
                ].filter(e => this.$role(e.roles)),
                filters: {
                    order_column: null,
                    order: null,
                    search: null,
                    limit: 5
                },
                timeout: null,
            }
        };
    },
    created() {
        this.getShotPattern();
    },
    watch: {
        'configTableShotPattern.filters': {
            handler() {
                clearTimeout(this.configTableShotPattern.timeout);
                this.configTableShotPattern.timeout = setTimeout(this.getShotPattern, 500)
            },
            deep: true
        }
    },
    methods: {
        formatPhone,
        getShotPattern(page = 1) {
            this.loading = true;
            const params = {page, ...this.configTableShotPattern.filters};

            api
                .get('/shots/patterns', {params})
                .then(response => {
                    const {type, body, misc} = response.data;

                    if (type != 'success') return;
                    if (page > 1) this.configTableShotPattern.items.push(...body);
                    else this.configTableShotPattern.items = body;

                    this.configTableShotPattern.misc = misc;
                })
                .finally(() => this.loading = false);
        },
        deleteShotPattern(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja remover o modelo de disparo selecionado?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/shots/patterns/${id}`)
                        .then(res => {
                            const {type, message} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableShotPattern.items.findIndex(e => e._id == id);
                            this.configTableShotPattern.misc.total -= 1;
                            if (i >= 0) this.configTableShotPattern.items.splice(i, 1);
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível remover este modelo de disparo', 'danger');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        duplicateShotPattern(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja duplicar o modelo de disparo selecionado?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Duplicar',
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.post(`/shots/patterns/${id}`)
                        .then(res => {
                            const {type, message, body} = res.data;
                            if (type != 'success') {
                                throw new Error(message)
                            }
                            const i = this.configTableShotPattern.items.findIndex(e => e._id == id);
                            this.configTableShotPattern.items.splice(i + 1, 0, body);
                            this.configTableShotPattern.misc.total += 1;
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível duplicar este modelo de disparo', 'danger');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            })
        },
        editShotPattern(id) {
            this.$router.push({
                name: 'shots/patterns/edit',
                params: {id}
            });
        },
    }
};
</script>

<style>
</style>
